import { db } from "@/db/db";
import {
  account,
  accountEvent,
  audioEncoding,
  broadcastAction,
  broadcastRecipient,
  deviceRegistration,
  directWsInvitation,
  displayArtifact,
  feed,
  feedGroup,
  feedGroupMembership,
  feedPreference,
  file,
  item,
  link,
  permission,
  pipelineArtifactMetadata,
  publishedWorkflowItem,
  scheduleTrigger,
  template,
  transcription,
  workflowItem,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
  wsMembershipAvailability,
} from "@/db/schema";
import {
  Account,
  AccountEvent,
  AudioEncoding,
  DisplayArtifact,
  Item,
  MembershipAvailability,
  PipelineArtifactMetadata,
  Transcription,
  WorkspaceCommandAlias,
} from "@/db/types";
import { unreadClearEvents } from "@/models/UnreadsContextProvider";
import { chunkWithMinSize } from "@/utils";
import { logger } from "@/utils/logging";
import { and, desc, eq, getTableColumns, inArray, lt, ne, or, sql } from "drizzle-orm";
import {
  DeviceRegistration,
  UserInfoResponse,
  Workspace,
  WorkspaceMembership,
  WsAccount,
  WsAudioEncoding,
  WsBroadcastAction,
  WsBroadcastRecipient,
  WsCommandAlias,
  DirectWorkspaceInvitation as WsDirectWorkspaceInvitation,
  WsDisplayArtifact,
  WsEvent,
  WsFeed,
  WsFeedGroup,
  WsFeedGroupMembership,
  WsFeedPreference,
  WsFile,
  WsItem,
  WsLink,
  WsPAM,
  WsPermission,
  WsPublishedWorkflowItem,
  WsScheduleTrigger,
  WsTemplate,
  WsTranscription,
  WsWorkflowItem,
} from "web-client/api/data-contracts";
import { updateWorkspaceConfig } from "../workspaceConfig";
import { executeBuiltQuery, executeQuery } from "@/db/drizzleUtils";
type dbType = typeof db;
type dxType = Parameters<Parameters<dbType["transaction"]>[0]>[0];

export async function upsertMyAccount(userInfo: UserInfoResponse) {
  const accountId = userInfo?.session?.credentials?.[0].CredentialScopes?.[0].accountId;

  if (!accountId) {
    logger(["No account id found in userInfo", userInfo], true);
    return;
  }

  const data: Account = {
    id: accountId,
    mine: true,
    preferredLanguage: null,
    name: userInfo?.name,
    accountType: userInfo?.accountType,
    avatarColor: userInfo?.avatarColor,
    email: userInfo?.email,

    firstName: null,
    lastName: null,
    phoneNumber: null,
    emailVerified: false,
    phoneNumberVerified: false,
  };

  const results = await executeBuiltQuery(
    db
      .insert(account)
      .values(data)
      .onConflictDoUpdate({
        target: account.id,
        set: {
          mine: true,
          name: userInfo?.name,
          accountType: userInfo?.accountType,
          avatarColor: userInfo?.avatarColor,
          email: userInfo?.email,
        },
      })
      .returning({
        ...getTableColumns(account),
      }),
    "upsertMyAccount"
  );

  logger(["Upserted account", results]);

  return results;
}

export async function upsertDeviceRegistration(deviceRegistrationRecord: DeviceRegistration) {
  const deviceRegistrationId = localStorage.getItem("deviceRegistrationId");
  // save to local storage on upsert
  if (deviceRegistrationId !== deviceRegistrationRecord?.id) {
    localStorage.setItem("deviceRegistrationId", `"${deviceRegistrationRecord?.id}"`);
  }

  return executeBuiltQuery(
    db.insert(deviceRegistration).values(deviceRegistrationRecord).onConflictDoUpdate({
      target: deviceRegistration.id,
      set: deviceRegistrationRecord,
    }),
    "upsertDeviceRegistration"
  );
}

export async function upsertWorkspace(workspaceRecord: Workspace) {
  updateWorkspaceConfig(workspaceRecord);
  return executeBuiltQuery(
    db.insert(workspace).values(workspaceRecord).onConflictDoUpdate({
      target: workspace.id,
      set: workspaceRecord,
    }),
    "upsertWorkspace"
  );
}

export async function upsertWsFeed(feedRecord: WsFeed) {
  const commandAliases = await db.query.workspaceCommandAlias.findFirst({
    where: eq(workspaceCommandAlias.feedId, feedRecord.id),
  });

  const results = await executeBuiltQuery(
    db
      .insert(feed)
      .values({ ...feedRecord, isAliasChannel: !!commandAliases })
      .onConflictDoUpdate({
        target: feed.id,
        set: feedRecord,
      }),
    "upsertWsFeed"
  );

  return results;
}

export async function upsertWsFeedPreference(feedPreferenceRecord: WsFeedPreference) {
  const results = await executeBuiltQuery(
    db.insert(feedPreference).values({ ...feedPreferenceRecord }).onConflictDoUpdate({
      target: feedPreference.id,
      set: feedPreferenceRecord,
    }),
    "upsertWsFeedPreference"
  );

  return results;
}
export async function upsertWsFeedPreferences(feedPreferenceRecords: WsFeedPreference[]) {
  const results = (
    await Promise.all(
      chunkWithMinSize(feedPreferenceRecords, 100).map(async (chunk) =>
        executeBuiltQuery(
          db
            .insert(feedPreference)
            .values(chunk)
            .onConflictDoUpdate({
              target: feedPreference.id,
              set: {
                handsFreeNotificationPreference: sql`excluded."handsFreeNotificationPreference"`,
                pushNotificationPreference: sql`excluded."pushNotificationPreference"`,
              },
            }),
          "upsertWsFeedPreferences"
        ),
      ),
    )
  ).flat();

  return results;
}

export async function upsertWsFeedGroup(feedGroupRecord: WsFeedGroup) {
  return executeBuiltQuery(
    db.insert(feedGroup).values(feedGroupRecord).onConflictDoUpdate({
      target: feedGroup.id,
      set: feedGroupRecord,
    }),
    "upsertWsFeedGroup"
  );
}

export async function upsertWsFeedGroupMembership(feedGroupMembershipRecord: WsFeedGroupMembership) {
  return executeBuiltQuery(
    db.insert(feedGroupMembership).values(feedGroupMembershipRecord).onConflictDoUpdate({
      target: feedGroupMembership.id,
      set: feedGroupMembershipRecord,
    }),
    "upsertWsFeedGroupMembership"
  );
}

export async function upsertWorkspaceMembership(workspaceMembershipRecord: WorkspaceMembership) {
  return executeBuiltQuery(
    db
      .insert(workspaceMembership)
      .values(workspaceMembershipRecord)
      .onConflictDoUpdate({
        target: workspaceMembership.id,
        set: workspaceMembershipRecord,
      }),
    "upsertWorkspaceMembership"
  );
}

export async function upsertWsPermission(permissionRecord: WsPermission) {
  return executeBuiltQuery(
    db.insert(permission).values(permissionRecord).onConflictDoUpdate({
      target: workspaceMembership.id,
      set: permissionRecord,
    }),
    "upsertWsPermission"
  );
}
export async function upsertWsAccount(accountRecord: WsAccount) {
  return executeBuiltQuery(
    db.insert(account).values(accountRecord).onConflictDoUpdate({
      target: account.id,
      set: accountRecord,
    }),
    "upsertWsAccount"
  );
}

interface FeedAccountPair {
  feedId: string;
  accountId: string;
}

async function checkCommandAliasesForPairs(dx: dbType | dxType, pairs: FeedAccountPair[]) {
  if (pairs.length === 0) return new Map<string, boolean>();

  // Create a list of conditions for all given pairs
  const conditions = pairs.map((pair) =>
    and(eq(workspaceCommandAlias.feedId, pair.feedId), eq(workspaceMembership.accountId, pair.accountId)),
  );

  // Fetch all matching aliases in a single query
  const results = await dx
    .select({
      feedId: workspaceCommandAlias.feedId,
      accountId: workspaceMembership.accountId,
    })
    .from(workspaceCommandAlias)
    .innerJoin(workspaceMembership, eq(workspaceCommandAlias.workspaceMembershipId, workspaceMembership.id))
    .where(or(...conditions))
    .execute();

  return new Map<string, boolean>(results.map((row) => [`${row.feedId}:${row.accountId}`, true]));
}

export async function bulkUpsertWsItems(itemRecords: WsItem[], fromAppSync: boolean = false) {
  const allResults = [];

  const pairs = itemRecords.map((itemRecord) => ({
    feedId: itemRecord.feedId,
    accountId: itemRecord.accountId,
  }));

  const aliasMap = await checkCommandAliasesForPairs(db, pairs);

  const allDataSet = itemRecords.map((itemRecord) => {
    const isFromAliasMemberBoolean = aliasMap.has(`${itemRecord.feedId}:${itemRecord.accountId}`);

    const data: Item = {
      id: itemRecord.id,
      feedId: itemRecord.feedId,
      accountId: itemRecord.accountId,
      contentId: itemRecord.contentId,
      createdAt: itemRecord.createdAt,
      deletedAt: itemRecord.deletedAt,
      groupId: itemRecord.groupId,
      isSilent: itemRecord.isSilent,
      fromAppSync: fromAppSync,
      isFromAliasMember: isFromAliasMemberBoolean,
      firstInsert: true,
    };

    return data;
  });
  for (const dataSet of chunkWithMinSize(allDataSet, 100)) {
    const parialResults = await executeBuiltQuery<Item>(
      db
        .insert(item)
        .values(dataSet)
        .onConflictDoUpdate({
          target: item.id,
          set: {
            firstInsert: false,
            deletedAt: sql`excluded."deletedAt"`,
            groupId: sql`excluded."groupId"`,
          },
        })
        .returning({
          ...getTableColumns(item),
        }),
      "bulkUpsertWsItems"
    );
    allResults.push(parialResults);
  }

  // Aggregate the maximum createdAt times per feed
  const feedUpdatesMap = new Map<string, { maxCreatedAt: string; maxAliasCreatedAt?: string }>();

  for (const itemRecord of allDataSet) {
    const feedId = itemRecord.feedId;
    const currentEntry = feedUpdatesMap.get(feedId) || {
      maxCreatedAt: null,
      maxAliasCreatedAt: null,
    };

    // Update maxCreatedAt if this item is newer
    if (!currentEntry.maxCreatedAt || itemRecord.createdAt > currentEntry.maxCreatedAt) {
      currentEntry.maxCreatedAt = itemRecord.createdAt;
    }

    // If from alias member, update maxAliasCreatedAt if this item is newer
    if (itemRecord.isFromAliasMember) {
      if (!currentEntry.maxAliasCreatedAt || itemRecord.createdAt > currentEntry.maxAliasCreatedAt) {
        currentEntry.maxAliasCreatedAt = itemRecord.createdAt;
      }
    }

    feedUpdatesMap.set(feedId, currentEntry);
  }

  const allFeedUpdateTuples = Array.from(feedUpdatesMap.entries()).map(
    ([fid, { maxCreatedAt, maxAliasCreatedAt }]) =>
      sql`(${sql.join([fid, maxCreatedAt ?? "-infinity", maxAliasCreatedAt ?? "-infinity"], sql`, `)})`,
  );

  for (const feedUpdateTuples of chunkWithMinSize(allFeedUpdateTuples, 2000)) {
    if (feedUpdateTuples.length > 0) {
      const updateSql = sql`
        WITH feeds_to_update (id, max_created_at, max_alias_created_at) AS (
          VALUES ${sql.join(feedUpdateTuples, sql`, `)}
        )
        UPDATE ${feed}
        SET 
          "latestActivity" = GREATEST(
              ${feed}."latestActivity", 
              COALESCE(feeds_to_update.max_created_at, '-infinity')::TIMESTAMP
            ),
          "latestAliasActivity" = GREATEST(
            ${feed}."latestAliasActivity", 
            COALESCE(feeds_to_update.max_alias_created_at, '-infinity')::TIMESTAMP
          )
        FROM feeds_to_update
        WHERE ${feed}.id = feeds_to_update.id
      `;
      await executeQuery(updateSql, "bulkUpsertWsItems");
    }
  }
  return allResults.flat();
}

export async function upsertWsItem(itemRecord: WsItem, fromAppSync: boolean = false, dx: dbType | dxType = db) {
  const isFromAliasMember = await executeBuiltQuery(
    dx
      .select()
      .from(workspaceCommandAlias)
      .innerJoin(workspaceMembership, and(eq(workspaceCommandAlias.workspaceMembershipId, workspaceMembership.id)))
      .where(
        and(
          eq(workspaceCommandAlias.feedId, itemRecord.feedId),
          eq(workspaceMembership.accountId, itemRecord.accountId),
        ),
      ),
    "upsertWsItem"
  );

  const isFromAliasMemberBoolean = isFromAliasMember?.length > 0;

  const data: Item = {
    id: itemRecord.id,
    feedId: itemRecord.feedId,
    accountId: itemRecord.accountId,
    contentId: itemRecord.contentId,
    createdAt: itemRecord.createdAt,
    deletedAt: itemRecord.deletedAt,
    groupId: itemRecord.groupId,
    isSilent: itemRecord.isSilent,
    fromAppSync: fromAppSync,
    isFromAliasMember: isFromAliasMemberBoolean,
    firstInsert: true,
  };

  const results = await executeBuiltQuery(
    dx
      .insert(item)
      .values(data)
      .onConflictDoUpdate({
        target: item.id,
        set: {
          groupId: itemRecord.groupId,
          isSilent: itemRecord.isSilent,
          deletedAt: itemRecord.deletedAt,
          createdAt: itemRecord.createdAt,
          isFromAliasMember: isFromAliasMemberBoolean,
          firstInsert: false,
        },
      })
      .returning({
        ...getTableColumns(item),
      }),
    "upsertWsItem"
  );

  await executeBuiltQuery(
    dx.update(feed).set({ latestActivity: itemRecord.createdAt }).where(and(eq(feed.id, itemRecord.feedId), lt(feed.latestActivity, itemRecord.createdAt))),
    "updateFeedLatestActivity"
  );

  if (isFromAliasMember) {
    await executeBuiltQuery(
      dx.update(feed).set({ latestAliasActivity: itemRecord.createdAt }).where(and(eq(feed.id, itemRecord.feedId), lt(feed.latestAliasActivity, itemRecord.createdAt))),
      "updateFeedLatestAliasActivity"
    );
  }

  return results;
}

export async function upsertWsDisplayArtifact(displayRecord: WsDisplayArtifact) {
  const data: DisplayArtifact = {
    id: displayRecord.id,
    contentId: displayRecord.contentId,
    createdAt: displayRecord.createdAt,
    deletedAt: displayRecord.deletedAt,
    description: displayRecord.description,
    title: displayRecord.title,
  };

  return executeBuiltQuery(
    db.insert(displayArtifact).values(data).onConflictDoUpdate({
      target: feed.id,
      set: displayRecord,
    }),
    "upsertWsDisplayArtifact"
  );
}

export async function bulkUpsertWsTranscriptions(transcriptionRecords: WsTranscription[]) {
  const allDataSet = transcriptionRecords.map((transcriptionRecord) => {
    const data: Transcription = {
      id: transcriptionRecord.id,
      createdAt: transcriptionRecord.createdAt,
      contentId: transcriptionRecord.contentId,
      transcriptionContent: transcriptionRecord.transcription,
      transcriptionType: transcriptionRecord.transcriptionType,
      backendModel: transcriptionRecord.model,
      confidence: transcriptionRecord.confidence?.toString(),
      executionTime: transcriptionRecord.executionTime?.toString(),
      language: transcriptionRecord.language,
      priority: transcriptionRecord.priority,
      url: transcriptionRecord.url,
      translatedFrom: transcriptionRecord.translatedFrom,
      format: transcriptionRecord.format,
    };
    return data;
  });
  const allResults = [];
  for (const dataSet of chunkWithMinSize(allDataSet, 1000)) {
    allResults.push(
      executeBuiltQuery(
        db
          .insert(transcription)
          .values(dataSet)
          .onConflictDoUpdate({
            target: transcription.id,
            set: {
              transcriptionContent: sql`excluded."transcriptionContent"`,
            },
          }),
        "bulkUpsertWsTranscriptions"
      )
    );
  }
  return allResults.flat();
}

export async function upsertWsTranscription(transcriptionRecord: WsTranscription, dx: dbType | dxType = db) {
  const data: Transcription = {
    id: transcriptionRecord.id,
    createdAt: transcriptionRecord.createdAt,
    contentId: transcriptionRecord.contentId,
    transcriptionContent: transcriptionRecord.transcription,
    transcriptionType: transcriptionRecord.transcriptionType,
    backendModel: transcriptionRecord.model,
    confidence: transcriptionRecord.confidence?.toString(),
    executionTime: transcriptionRecord.executionTime?.toString(),
    language: transcriptionRecord.language,
    priority: transcriptionRecord.priority,
    url: transcriptionRecord.url,
    translatedFrom: transcriptionRecord.translatedFrom,
    format: transcriptionRecord.format,
  };

  return executeBuiltQuery(
    dx.insert(transcription).values(data).onConflictDoUpdate({
      target: transcription.id,
      set: transcriptionRecord,
    }),
    "upsertWsTranscription"
  );
}

export async function bulkUpsertWsAudioEncodings(audioEncodingRecords: WsAudioEncoding[]) {
  const dataSet = audioEncodingRecords.map((audioEncodingRecord) => {
    const data: AudioEncoding = {
      id: audioEncodingRecord.id,
      contentId: audioEncodingRecord.contentId,
      createdAt: audioEncodingRecord.createdAt,
      codec: audioEncodingRecord.codec,
      duration: audioEncodingRecord.duration.toString(),
      generatedService: audioEncodingRecord.generatedService,
      url: audioEncodingRecord.url,
      generatedVoice: audioEncodingRecord.generatedVoice,
      language: audioEncodingRecord.language,
      mimeType: audioEncodingRecord.mimeType,
      priority: audioEncodingRecord.priority,
      transcriptionId: audioEncodingRecord.transcriptionId,
      transcriptionType: audioEncodingRecord.transcriptionType,
      translatedFrom: audioEncodingRecord.translatedFrom,
    };
    return data;
  });
  const allResults = [];
  for (const dataSetChunk of chunkWithMinSize(dataSet, 1000)) {
    allResults.push(executeBuiltQuery(
      db.insert(audioEncoding).values(dataSetChunk).onConflictDoNothing({}),
      "bulkUpsertWsAudioEncodings"
    ));
  }
  return allResults.flat();
}

export async function upsertWsAudioEncoding(audioEncodingRecord: WsAudioEncoding, dx: dbType | dxType = db) {
  const data: AudioEncoding = {
    id: audioEncodingRecord.id,
    contentId: audioEncodingRecord.contentId,
    createdAt: audioEncodingRecord.createdAt,
    codec: audioEncodingRecord.codec,
    duration: audioEncodingRecord.duration.toString(),
    generatedService: audioEncodingRecord.generatedService,
    url: audioEncodingRecord.url,
    generatedVoice: audioEncodingRecord.generatedVoice,
    language: audioEncodingRecord.language,
    mimeType: audioEncodingRecord.mimeType,
    priority: audioEncodingRecord.priority,
    transcriptionId: audioEncodingRecord.transcriptionId,
    transcriptionType: audioEncodingRecord.transcriptionType,
    translatedFrom: audioEncodingRecord.translatedFrom,
  };
  return executeBuiltQuery(
    dx.insert(audioEncoding).values(data).onConflictDoUpdate({
      target: audioEncoding.id,
      set: data,
    }),
    "upsertWsAudioEncoding"
  );
}

export async function upsertWsLink(linkRecord: WsLink) {
  return executeBuiltQuery(
    db.insert(link).values(linkRecord).onConflictDoUpdate({
      target: link.id,
      set: linkRecord,
    }),
    "upsertWsLink"
  );
}

export async function upsertWsFile(fileRecord: WsFile) {
  return executeBuiltQuery(
    db.insert(file).values(fileRecord).onConflictDoUpdate({
      target: link.id,
      set: fileRecord,
    }),
    "upsertWsFile"
  );
}

// TODO: Move this to a better place
export const transformMemberAvailabilityEvent = (eventRecord: WsEvent) => ({
  id: eventRecord.id,
  accountId: eventRecord.accountId,
  createdAt: eventRecord.createdAt,
  name: eventRecord.name,
  contentId: eventRecord?.contentId ?? null,
  feedId: eventRecord?.feedId ?? null,
  itemId: eventRecord?.itemId ?? null,
  memberAvailability: eventRecord?.memberAvailability ?? null,
  deviceVolume: eventRecord?.deviceVolume ?? null,
});
export async function upsertWsEvent(eventRecord: WsEvent) {
  const data: AccountEvent = transformMemberAvailabilityEvent(eventRecord);
  return executeBuiltQuery(
    db.insert(accountEvent).values(data).onConflictDoUpdate({
      target: accountEvent.id,
      set: data,
    }),
    "upsertWsEvent"
  );
}

export async function upsertDirectWsInvitation(directWsInvitationRecord: WsDirectWorkspaceInvitation) {
  return executeBuiltQuery(
    db.insert(directWsInvitation).values(directWsInvitationRecord).onConflictDoUpdate({
      target: accountEvent.id,
      set: directWsInvitationRecord,
    }),
    "upsertDirectWsInvitation"
  );
}

export async function upsertWsScheduleTrigger(scheduleTriggerRecord: WsScheduleTrigger) {
  // force true to be an integer until migrations can be fixed
  const scheduleTriggerRecordAsInteger = {
    ...scheduleTriggerRecord,
    enabled: scheduleTriggerRecord?.enabled ? 1 : (0 as number),
  };

  return executeBuiltQuery(
    db.insert(scheduleTrigger).values(scheduleTriggerRecordAsInteger).onConflictDoUpdate({
      target: accountEvent.id,
      set: scheduleTriggerRecordAsInteger,
    }),
    "upsertWsScheduleTrigger"
  );
}

export async function upsertWsBroadcastAction(broadcastActionRecord: WsBroadcastAction) {
  return executeBuiltQuery(
    db.insert(broadcastAction).values(broadcastActionRecord).onConflictDoUpdate({
      target: accountEvent.id,
      set: broadcastActionRecord,
    }),
    "upsertWsBroadcastAction"
  );
}

export async function upsertWsDraft(draftRecord: WsWorkflowItem) {
  return executeBuiltQuery(
    db.insert(workflowItem).values(draftRecord).onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: draftRecord,
    }),
    "upsertWsDraft"
  );
}

export async function upsertWsPublishedDraft(publishedDraftRecord: WsPublishedWorkflowItem) {
  return executeBuiltQuery(
    db.insert(publishedWorkflowItem).values(publishedDraftRecord).onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: publishedDraftRecord,
    }),
    "upsertWsPublishedDraft"
  );
}

export async function upsertWsBroadcastRecipient(broadcastRecipientRecord: WsBroadcastRecipient) {
  return executeBuiltQuery(
    db.insert(broadcastRecipient).values(broadcastRecipientRecord).onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: broadcastRecipientRecord,
    }),
    "upsertWsBroadcastRecipient"
  );
}

export async function upsertWsTemplate(templateRecord: WsTemplate) {
  return executeBuiltQuery(
    db.insert(template).values(templateRecord).onConflictDoUpdate({
      target: publishedWorkflowItem.id,
      set: templateRecord,
    }),
    "upsertWsTemplate"
  );
}

export async function upsertWsMembershipAvailability(handsFreeStatusRecord: MembershipAvailability) {
  return executeBuiltQuery(
    db.insert(wsMembershipAvailability).values(handsFreeStatusRecord).onConflictDoUpdate({
      target: wsMembershipAvailability.workspaceMembershipId,
      set: handsFreeStatusRecord,
    }),
    "upsertWsMembershipAvailability"
  );
}

export async function upsertWsPAM(pamRecord: WsPAM) {
  const data: PipelineArtifactMetadata = {
    id: pamRecord.id,
    createdAt: pamRecord.createdAt,
    contentId: pamRecord.contentId,
    updatedAt: pamRecord.updatedAt,
    vadResult: null,
  };
  return executeBuiltQuery(
    db.insert(pipelineArtifactMetadata).values(data).onConflictDoUpdate({
      target: pipelineArtifactMetadata.id,
      set: pamRecord,
    }),
    "upsertWsPAM"
  );
}

export async function upsertWsCommandAlias(commandAliasRecord: WsCommandAlias) {
  const data: WorkspaceCommandAlias = {
    id: commandAliasRecord.id,
    workspaceId: commandAliasRecord.workspaceId,
    alias: commandAliasRecord.alias,
    createdAt: commandAliasRecord.createdAt,
    workspaceMembershipId: commandAliasRecord.workspaceMembershipId,
    feedId: commandAliasRecord.feedId,
  };

  const results = await executeBuiltQuery(
    db.insert(workspaceCommandAlias).values(data).onConflictDoUpdate({
      target: workspaceCommandAlias.id,
      set: commandAliasRecord,
    }),
    "upsertWsCommandAlias"
  );

  await executeBuiltQuery(
    db.update(feed).set({ isAliasChannel: true }).where(eq(feed.id, commandAliasRecord.feedId)),
    "upsertWsCommandAlias"
  );

  return results;
}

export async function updateUnreadItemByEvents(event: WsEvent, myAccountId: string, workspaceId: string) {
  const isClearEvent = unreadClearEvents.includes(event.name) && event.feedId;
  if (!isClearEvent) {
    return false;
  }
  const commandAliases = await db.query.workspaceCommandAlias
    .findMany({
      where: eq(workspaceCommandAlias.feedId, event.feedId),
    })
    .execute();
  const isAliasChannel = commandAliases?.length > 0;
  const myCurrentWorkspaceMembership = await db.query.workspaceMembership.findFirst({
    where: and(eq(workspaceMembership.accountId, myAccountId), eq(workspaceMembership.workspaceId, workspaceId)),
  });
  const myCurrentWorkspaceRole = myCurrentWorkspaceMembership?.role;
  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;
  const otherOrganizers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "member"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const mappedOrganizers = otherOrganizers?.map((organizer) => organizer?.accountId);

  const isOrganizerEvent = mappedOrganizers?.includes(event?.accountId);
  let itemsToUpdate = [];
  if (event?.accountId === myAccountId || (isOrganizer && isOrganizerEvent)) {
    if (event?.itemId) {
      itemsToUpdate.push(event?.itemId);
    } else {
      const unreadItems = await db.query.item
        .findMany({
          where: and(eq(item.feedId, event.feedId), eq(item.unread, true)),
        })
        .execute();
      itemsToUpdate = unreadItems?.map((item) => item?.id);
    }
  }

  if (itemsToUpdate?.length > 0) {
    return executeBuiltQuery(
      db.update(item).set({ unread: false }).where(inArray(item.id, itemsToUpdate)),
      "updateUnreadItemByEvents"
    );
  }
  return false;
}

export async function updateUnreadItem(wsItem: WsItem, myAccountId: string, workspaceId: string) {
  const itemRecord = await db.query.item
    .findFirst({
      where: and(eq(item.id, wsItem.id)),
    })
    .execute();
  if (!itemRecord) return false;
  const authorMembership = await db.query.workspaceMembership
    .findFirst({
      where: eq(workspaceMembership.accountId, itemRecord.accountId),
    })
    .execute();

  const myCurrentWorkspaceMembership = await db.query.workspaceMembership.findFirst({
    where: and(eq(workspaceMembership.accountId, myAccountId), eq(workspaceMembership.workspaceId, workspaceId)),
  });
  const myCurrentWorkspaceRole = myCurrentWorkspaceMembership?.role;

  logger(["checkedItem", itemRecord?.unread]);

  const feedId = itemRecord?.feedId;
  const commandAliases = await db.query.workspaceCommandAlias
    .findMany({
      where: eq(workspaceCommandAlias.feedId, itemRecord.feedId),
    })
    .execute();

  const isAliasChannel = commandAliases?.length > 0;

  const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;
  const isFromAliasMember = !!commandAliases?.find((alias) => alias.workspaceMembershipId === authorMembership?.id);

  const otherOrganizers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "member"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const otherMembers = await db
    .select({ accountId: workspaceMembership.accountId })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.role, "limitedMember"),
        ne(workspaceMembership.accountId, myAccountId),
      ),
    )
    .execute();

  const mappedOrganizers = new Map(otherOrganizers?.map((organizer) => [organizer?.accountId, true]));
  const mappedMembers = new Map(otherMembers?.map((member) => [member.accountId, true]));

  const feedReadPermission = await db.query.permission
    .findFirst({
      where: and(
        eq(permission.feedId, feedId),
        eq(permission.accountId, myAccountId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    })
    .execute();
  let shouldMarkItemAsUnread = false;

  if (isOrganizer && isAliasChannel) {
    mappedOrganizers.set(myAccountId, true);

    const feedEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          or(eq(accountEvent.feedId, feedId)),
          inArray(accountEvent.accountId, [...mappedOrganizers.keys()]),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const itemEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          eq(accountEvent.itemId, itemRecord.id),
          inArray(accountEvent.accountId, [...mappedOrganizers.keys()]),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const eventDate = !feedEvent
      ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || ""
      : feedEvent?.createdAt;
    shouldMarkItemAsUnread =
      itemRecord.accountId !== myAccountId &&
      mappedMembers.has(itemRecord.accountId) &&
      new Date(eventDate) <= new Date(itemRecord?.createdAt) &&
      !itemEvent;
  } else {
    const feedEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          eq(accountEvent.feedId, feedId),
          eq(accountEvent.accountId, myAccountId),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const itemEvent = await db.query.accountEvent
      .findFirst({
        where: and(
          eq(accountEvent.itemId, itemRecord.id),
          eq(accountEvent.accountId, myAccountId),
          inArray(accountEvent.name, unreadClearEvents),
        ),
        orderBy: desc(accountEvent.createdAt),
      })
      .execute();
    const eventDate = !feedEvent
      ? feedReadPermission?.updatedAt || feedReadPermission?.createdAt || ""
      : feedEvent?.createdAt;
    shouldMarkItemAsUnread =
      itemRecord.accountId !== myAccountId && new Date(eventDate) <= new Date(itemRecord?.createdAt) && !itemEvent;
  }
  if (shouldMarkItemAsUnread) {
    return executeBuiltQuery(
      db.update(item).set({ unread: true, isFromAliasMember }).where(eq(item.id, itemRecord.id)),
      "updateUnreadItem"
    );
  } else {
    return executeBuiltQuery(
      db.update(item).set({ unread: false, isFromAliasMember }).where(eq(item.id, itemRecord.id)),
      "updateUnreadItem"
    );
  }
}

export async function upsertManyWsPermission(wsPermissions: WsPermission[]) {
  wsPermissions.map((permissionRecord) => upsertWsPermission(permissionRecord));
}

export async function deleteWsDraft(draftId: string) {
  return executeBuiltQuery(
    db.delete(workflowItem).where(eq(workflowItem.id, draftId)),
    "deleteWsDraft"
  );
}
