import { useLiveIncrementalQuery, useLiveQuery } from "@electric-sql/pglite-react";
import type { LiveQuery, LiveQueryResults } from "@electric-sql/pglite/live";
import { trace } from "@opentelemetry/api";
import { SQL, SQLWrapper } from "drizzle-orm";
import { PgDialect, type PgSelectBase } from "drizzle-orm/pg-core";
import type { PgRelationalQuery } from "drizzle-orm/pg-core/query-builders/query";
import log from "loglevel";
import { useEffect, useRef, useState } from "react";
import { db } from "./db";

const debugQuery = false;

function printDebugQuery(name: string, query) {
  if (debugQuery) {
    log.debug(query.toSQL());
  }
}

export function useDrizzleIncSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
) {
  printDebugQuery("select query", query);
  const results = useLiveIncrementalQuery(query.toSQL().sql, query.toSQL().params, "id");
  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleSelect<
  TTableName,
  TSelection,
  TSelectMode,
  TNullabilityMap,
  TDynamic,
  TExcludedMethods,
  TResult,
  TSelectedFields,
  T,
>(
  query: Omit<
    PgSelectBase<
      TTableName,
      TSelection,
      TSelectMode,
      TNullabilityMap,
      TDynamic,
      TExcludedMethods,
      TResult,
      TSelectedFields
    >,
    T
  >,
  debugName?: string,
) {
  const q = query.toSQL();
  const qSql = q.sql;
  const qParams = q.params;

  const results = useLiveQueryImpl(qSql, qParams, debugName);
  const rows = (results?.rows || []) as unknown as Awaited<typeof query>;
  return { rows };
}

export function useDrizzleRawQuery<T>(query: SQL<unknown>) {
  if (debugQuery) {
    log.debug("raw query", query);
  }
  return useLiveQuery(`${query}`) || [];
}

export function useDrizzleIncQuery<T>(query: PgRelationalQuery<T>) {
  printDebugQuery("inc query", query);

  const results = useLiveIncrementalQuery(query.toSQL().sql, query.toSQL().params, "id");
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}

export function useDrizzleQuery<T>(query?: PgRelationalQuery<T>, debugName?: string) {
  printDebugQuery("query", query);
  // const results = useLiveQuery(query.toSQL().sql, query.toSQL().params);
  const results = useLiveQueryImpl(query.toSQL().sql, query.toSQL().params, debugName);
  const rows = (results?.rows || []) as Awaited<T>;
  return { rows };
}

function paramsEqual(a1: unknown[] | undefined | null, a2: unknown[] | undefined | null) {
  if (!a1 && !a2) return true;
  if (a1?.length !== a2?.length) return false;
  for (let i = 0; i < a1?.length; i++) {
    if (!Object.is(a1?.[i], a2?.[i])) {
      return false;
    }
  }
  return true;
}

// Common logging function with table extraction logic
function logQueryPerformance(duration: number, query: string, type: "LIVE" | "STATIC", name?: string) {
  const extractFromTableFromQuery = (query: string) => {
    // Match FROM clause with optional quotes around table name
    const fromMatch = query.match(/FROM\s+(?:"|')?(\w+)(?:"|')?/i);
    // Match INSERT INTO clause with optional quotes around table name
    const insertMatch = query.match(/INSERT\s+INTO\s+(?:"|')?(\w+)(?:"|')?/i);

    if (fromMatch) {
      return fromMatch[1];
    } else if (insertMatch) {
      return insertMatch[1];
    }

    return null;
  };

  const table = extractFromTableFromQuery(query);

  if (duration > 1000) {
    log.warn(
      `\x1b[31m[PG][${type}][${duration.toFixed(2)} ms] Query executed ${table ? `on ${table}` : ""}${name ? `: ${name}` : ""}\x1b[0m`,
      { query, duration, table, name },
    );
  } else if (duration > 100) {
    log.info(
      `\x1b[33m[PG][${type}][${duration.toFixed(2)} ms] Query executed ${table ? `on ${table}` : ""}${name ? `: ${name}` : ""}\x1b[0m`,
      { query, duration, table, name },
    );
  } else {
    log.debug(
      `[PG][${type}][${duration.toFixed(2)} ms] Query executed ${table ? `on ${table}` : ""}${name ? `: ${name}` : ""}`,
      { query, duration, table, name },
    );
  }
}

function useLiveQueryImpl<T = { [key: string]: unknown }>(
  query: string | LiveQuery<T> | Promise<LiveQuery<T>>,
  params: unknown[] | undefined | null,
  debugName?: string,
): Omit<LiveQueryResults<T>, "affectedRows"> | undefined {
  const pg = db.$client;
  const paramsRef = useRef(params);
  const [results, setResults] = useState<LiveQueryResults<T> | undefined>();
  let paramsChanged = false;

  let currentParams = paramsRef.current;
  if (!paramsEqual(paramsRef.current, params)) {
    paramsRef.current = params;
    currentParams = params;
    paramsChanged = true;
  }

  /* eslint-disable @eslint-react/hooks-extra/no-direct-set-state-in-use-effect */
  useEffect(() => {
    let cancelled = false;
    let startTime = performance.now();
    const cb = (results: LiveQueryResults<T>) => {
      if (cancelled) return;

      if (startTime) {
        const endTime = performance.now();
        const duration = endTime - startTime;
        logQueryPerformance(duration, query as string, "LIVE", debugName);
        startTime = undefined;
      }
      setResults(results);
    };
    if (typeof query === "string") {
      const ret = pg.live.query<T>(query, currentParams, cb);

      return () => {
        cancelled = true;
        ret.then(({ unsubscribe }) => unsubscribe());
      };
    } else {
      throw new Error("Should never happen");
    }
  }, [pg, debugName, query, currentParams, paramsChanged]);
  /* eslint-enable @eslint-react/hooks-extra/no-direct-set-state-in-use-effect */

  if (debugName) {
    log.debug(`CUSTOM: ${debugName}`, results);
  }
  return (
    results && {
      rows: results.rows,
      fields: results.fields,
      totalCount: results.totalCount,
      offset: results.offset,
      limit: results.limit,
    }
  );
}

export async function executeQuery(query: SQL, name: string) {
  const dialect = new PgDialect({});
  const { sql, params } = dialect.sqlToQuery(query);

  const tracer = trace.getTracer("pg_query.tracer");
  const span = tracer.startSpan("pg_query");
  span.setAttribute("pg_query.name", name);

  return await db.$client.query(sql, params, {
    onNotice: (msg) => {
      const regex = /duration:\s([\d.]+)\sms\s+plan:\n(\{[\s\S]*\})/;
      const match = msg.message.match(regex);

      if (match) {
        const duration = Number(match[1]);
        const jsonString = match[2];

        try {
          const queryPlan = JSON.parse(jsonString);
          logQueryPerformance(duration, sql, "STATIC", name);

          span.setAttributes({
            "pg_query.durationMs": duration,
            "pg_query.queryPlan": JSON.stringify(queryPlan, null, 2),
          });
        } catch (error) {
          span.setAttributes({ error: true });
          span.recordException(error);
        } finally {
          span.end();
        }
      }
    },
  });
}

export async function executeBuiltQuery<T>(query: SQLWrapper, name: string): Promise<T[]> {
  return (await executeQuery(query.getSQL(), name)).rows as T[];
}
