import { Ampli } from "@/ampli";
import { client } from "@/config";
import {
  createManyWorkspace,
  createManyWorkspaceMembership,
  createManyWsAccount,
  createManyWsMembershipAvailability,
} from "@/data/pg/bulkInserts";
import {
  upsertDeviceRegistration,
  upsertMyAccount,
  upsertWsAudioEncoding,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsDisplayArtifact,
  upsertWsDraft,
  upsertWsFile,
  upsertWsLink,
  upsertWsScheduleTrigger,
  upsertWsTranscription,
} from "@/data/pg/updates";
import { db } from "@/db/db";
import { account, item, permission, workspaceMembership } from "@/db/schema";
import { Permission, WorkspaceMembership } from "@/db/types";
import UseStorage from "@/hooks/useStorage";
import useBootstrap from "@/models/BootstrapApplication";
import { type MappedWorkspaceRole, buildVersion } from "@/utils";
import { logger } from "@/utils/logging";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import * as Sentry from "@sentry/browser";
import { useLocalStorage } from "@uidotdev/usehooks";
import cuid from "cuid";
import { and, eq } from "drizzle-orm";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { DeviceRegistration, UserInfoResponse } from "web-client/api/data-contracts";
import { AppContext } from "./AppStateProvider";
import { subscribeToAppSync } from "./AppsyncHandler";
import { CurrentFeedContext } from "./StateProviders/currentFeedProvider";
import { MyAccountContext } from "./StateProviders/myAccountProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { TrackingContext } from "./TrackingStateProvider";
import { downloadPaginatedFeedPermissions } from "./actions/initialFeedLoad";
import { LANGUAGE_LIST, PreferredLanguage } from "./languages";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

let userValidated = false;

const LANGUAGE_SESSION_KEY = "preferredLanguage";
const LANGUAGE_DEFAULT_VALUE = LANGUAGE_LIST[0] ?? "none";

type DataState = {
  invalidWorkspace?: boolean;
  setInvalidWorkspace?: (value: boolean) => void;
  availableWorkspaceRoles?: Map<string, MappedWorkspaceRole>;
  bootstrapComplete?: boolean;
  firstBootWorkspaceId?: string;
  loadWorkspaceWorkflowItems?: (params: {
    workspaceId: string;
    myAccountId: string;
  }) => Promise<void>;
  preferredLanguage: PreferredLanguage;
  setPreferredLanguage?: (language: PreferredLanguage) => void;
  fetchWorkspaceMembership?: (workspaceMembershipId: string, feedId: string) => Promise<WorkspaceMembership>;
  listFeedPermissions?: (workspaceMembershipId: string, feedId: string) => Promise<Array<Permission>>;
  fetchWorkspaceMembershipAvailabilities?: (workspaceId: string) => Promise<void>;
  fetchGeoCoords?: (workspaceId: string, membershipIds: string[]) => Promise<{ lat: number; lng: number } | null>;
  fetchFeedPermissions?: (feedId: string) => Promise<void>;
  getWorkspaceRoleLabel?: (role: string) => string;
};

//create a context, with createContext api
export const DataContext = createContext<DataState>({
  preferredLanguage: "en",
  invalidWorkspace: false,
});

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const DataProvider = ({ children }: Props) => {
  const { pubSub } = React.useContext(AppContext);
  const { limitedMemberRole, amplitudeSessionTracking } = useFlags();
  const ldClient = useLDClient();
  const { ampli }: { ampli: Ampli } = React.useContext(TrackingContext);
  const { currentFeedId } = useContext(CurrentFeedContext);
  const [invalidWorkspace, setInvalidWorkspace] = useState<boolean>(false);
  const [bootstrapComplete, setBootstrapComplete] = useState<boolean>(false);
  const [firstBootWorkspaceId, setFirstBootWorkspaceId] = useState<string | null>(null);
  const [user, setUser] = useState<UserInfoResponse | null>(null);

  const { getLocalStorage, setLocalStorage } = UseStorage();

  const { myAccount } = React.useContext(MyAccountContext);
  const { currentWorkspaceId, workspaces } = React.useContext(WorkspaceContext);
  const [deviceRegistrationId] = useLocalStorage("deviceRegistrationId", "");

  const localStoragePreferredLanguage = getLocalStorage("preferredLanguage") as PreferredLanguage;

  const preferredLanguage = localStoragePreferredLanguage || LANGUAGE_DEFAULT_VALUE;

  const setPreferredLanguage = useCallback(
    async (language: PreferredLanguage) => {
      if (!myAccount?.id) return;
      console.log("setPreferredLanguage", language);
      await db.update(item).set({ loadedContent: false }).execute();
      await db
        .update(account)
        .set({ preferredLanguage: language })
        .where(eq(account.id, myAccount.id))
        .execute()
        .then(() => {
          setLocalStorage({ key: LANGUAGE_SESSION_KEY, value: language });
        });
    },
    [myAccount?.id, setLocalStorage],
  );

  useEffect(() => {
    //  handsFreeEnabled: handsFreeEnabledForWorkspace(currentWorkspaceId),
    client.appContext.selectedWorkspaceId = currentWorkspaceId;
    client.appContext.workspaces = workspaces?.map((w) => {
      return {
        //  handsFreeEnabled: handsFreeEnabledForWorkspace(w?.id) || false,
        workspaceId: w?.id || "",
        feedSelected: w?.id === currentWorkspaceId ? currentFeedId : undefined,
        languageSelected: preferredLanguage,
      };
    });
  }, [currentWorkspaceId, workspaces, currentFeedId, preferredLanguage]);

  useEffect(() => {
    client.deviceContext.surface = "web";
    client.deviceContext.surfaceContext = window?.navigator?.userAgent;
    client.deviceContext.surfaceBuild = buildVersion();
    client.deviceContext.notificationsEnabled = Notification?.permission === "granted";
    client.deviceContext.internetConnectionStatus = "online";
    client.deviceContext.deviceLanguage = window?.navigator?.language || preferredLanguage;
  }, [deviceRegistrationId, preferredLanguage]);

  // setup mapped workspace roles from api -> client label
  const availableWorkspaceRoles = new Map<string, MappedWorkspaceRole>([
    ["admin", { role: "admin", label: "Administrator", enabled: true }],
    ["member", { role: "member", label: "Organizer", enabled: true }],
    [
      "limitedMember",
      {
        role: "limitedMember",
        label: "Member",
        enabled: limitedMemberRole,
      },
    ],
  ]);

  const getWorkspaceRoleLabel = (role: string) => {
    return availableWorkspaceRoles?.get(role)?.label || role;
  };

  useEffect(() => {
    const validateUser = async () => {
      try {
        const userInfoResponse = await client.getUserInfo();

        // redirect the user to get the correct workspace bootstrap
        // if (userInfoResponse?.workspaces?.length > 0 && !params?.workspaceId) {
        //   window.location.href = `/workspaces/${userInfoResponse?.workspaces[0].id}`;
        //   return;
        // }

        if (userInfoResponse?.workspaces?.length > 0) {
          await createManyWorkspace(userInfoResponse?.workspaces || []);
        }
        if (userInfoResponse?.workspaceMemberships?.length > 0) {
          await createManyWorkspaceMembership(userInfoResponse?.workspaceMemberships || []);
        }
        if (userInfoResponse?.accounts?.length > 0) {
          await createManyWsAccount(userInfoResponse?.accounts || []);
        }
        const sessionStorageValue = window?.sessionStorage?.getItem(LANGUAGE_SESSION_KEY);
        if (sessionStorageValue) {
          logger(["FOUND SESSION STORAGE VALUE", sessionStorageValue]);
        } else {
          logger("NO SESSION STORAGE VALUE");
        }
        await upsertMyAccount(userInfoResponse);
        const accountRecord = await db.query.account
          .findFirst({
            where: eq(account.mine, true),
          })
          .execute();
        if (accountRecord) {
          if (!account?.preferredLanguage) {
            await db
              .update(account)
              .set({ preferredLanguage: sessionStorageValue || "none" })
              .where(eq(account.id, accountRecord.id))
              .execute();
          }
          ampli.client.setUserId(accountRecord.id);

          Sentry.setUser({ accountId: account.id });
          ampli.authSuccess({ "Auth0 App": "SMS" });
          ldClient
            .identify({
              kind: "user",
              key: accountRecord.id,
              name: accountRecord?.name,
              email: accountRecord?.email,
              sbAccount: accountRecord?.email?.includes("@storyboard.fm"),
            })
            .catch((e) => {
              console.error(e);
            });
          if (window.dataLayer) {
            window.dataLayer.push({
              event: {
                name: "authSuccess",
                value: "Auth0 App: SMS",
              },
            });
          }
        }
        console.log("DONE: User Info Response", userInfoResponse);
        setUser((prev) => (JSON.stringify(prev) !== JSON.stringify(userInfoResponse) ? userInfoResponse : prev));
      } catch (e) {
        const maintenancePage = window.location.pathname.includes("maintenance-mode");
        if (e?.status === 418 && !maintenancePage) {
          window.location.href = "/maintenance-mode";
        }
        return Promise.reject(e);
      }
    };
    if (!userValidated) {
      validateUser();
      userValidated = true;
    }
  }, []);

  useEffect(() => {
    if (amplitudeSessionTracking) {
      if (amplitudeSessionTracking) {
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 0.3,
        });
        amplitude.add(sessionReplayTracking);
      }
    }
  }, [amplitudeSessionTracking]);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(null);
  const { allComplete, activeWorkspaceId, invalidWorkspaceError, bootstrapPaginatedApplication } = useBootstrap();

  useEffect(() => {
    setInvalidWorkspace(() => invalidWorkspaceError);
  }, [invalidWorkspaceError]);

  useEffect(() => {
    let localWorkspaceId = null;
    if (selectedWorkspaceId && currentWorkspaceId && selectedWorkspaceId !== currentWorkspaceId) {
      localWorkspaceId = currentWorkspaceId;
    }

    if (!currentWorkspaceId && !selectedWorkspaceId && user?.workspaces?.length > 0) {
      localWorkspaceId = user.workspaces.find((w) => w.name.match(/dev/i))?.id || user.workspaces[0].id;
    }

    if (currentWorkspaceId && !selectedWorkspaceId) {
      localWorkspaceId = currentWorkspaceId;
    }

    const accountIds = user?.session?.credentials?.[0].CredentialScopes?.map((c) => c.accountId);
    // resubscribe to app sync
    subscribeToAppSync({
      accountId: accountIds?.[0],
      currentWorkspaceId: localWorkspaceId,
      pubSub,
      client,
      deviceRegistrationId,
    });

    if (localWorkspaceId) {
      bootstrapPaginatedApplication({
        workspaceId: localWorkspaceId,
        forceBootstrap: currentWorkspaceId !== localWorkspaceId,
      }).then(() => {
        setSelectedWorkspaceId(() => localWorkspaceId);
        localWorkspaceId = null;
      });
    } else if (user?.session?.credentials?.[0]?.id && !localWorkspaceId) {
      console.error("No workspace to bootstrap");
      setBootstrapComplete(() => true);
    }
  }, [user?.session?.credentials?.[0]?.id, currentWorkspaceId, selectedWorkspaceId]);

  useEffect(() => {
    if (allComplete) {
      setFirstBootWorkspaceId(() => activeWorkspaceId);
      setBootstrapComplete(() => allComplete);
    }
  }, [allComplete, activeWorkspaceId]);

  const loadWorkspaceWorkflowItems = async ({
    workspaceId,
    myAccountId,
  }: { workspaceId: string; myAccountId: string }) => {
    if (!myAccountId || !workspaceId) return;
    await client.getWorkspaceWorkflowItems(workspaceId).then((resp) => {
      console.log("Workspace workflow items response", { resp });
      for (const workflowItem of resp?.workflowItems || []) {
        upsertWsDraft(workflowItem);
      }
      // load associated content of the workflow items
      for (const i of resp?.audioEncodings || []) {
        upsertWsAudioEncoding(i);
      }
      for (const i of resp?.files || []) {
        upsertWsFile(i);
      }
      for (const i of resp?.links || []) {
        upsertWsLink(i);
      }
      for (const i of resp?.transcriptions || []) {
        upsertWsTranscription(i);
      }
      for (const i of resp?.displayArtifacts || []) {
        upsertWsDisplayArtifact(i);
      }
    });

    await client.getScheduledBroadcasts(workspaceId).then((resp) => {
      logger(["Workspace scheduled broadcasts response", { resp }]);
      for (const scheduleTrigger of resp?.scheduleTriggers || []) {
        upsertWsScheduleTrigger(scheduleTrigger);
      }
      for (const broadcastAction of resp?.broadcastActions || []) {
        upsertWsBroadcastAction(broadcastAction);
      }
      for (const broadcastRecipient of resp?.broadcastRecipients || []) {
        upsertWsBroadcastRecipient(broadcastRecipient);
      }
    });
  };

  const fetchWorkspaceMembership = async (accountId: string, workspaceId: string) => {
    logger(["fetchWorkspaceMembership", accountId, workspaceId]);
    const workspaceMembershipRecord = await db.query.workspaceMembership
      .findFirst({
        where: and(
          eq(workspaceMembership.accountId, accountId),
          eq(workspaceMembership.workspaceId, workspaceId),
          eq(workspaceMembership.status, "active"),
        ),
      })
      .execute();
    if (!workspaceMembershipRecord) {
      return Promise.reject("Invalid Workspace");
    }
    return workspaceMembershipRecord;
  };

  const listFeedPermissions = React.useCallback(
    async (workspaceMembershipId: string, feedId: string): Promise<Array<Permission>> => {
      logger(["listFeedPermissions", workspaceMembershipId, feedId]);
      return await db.query.permission
        .findMany({
          where: and(eq(permission.feedId, feedId), eq(permission.workspaceMembershipId, workspaceMembershipId)),
        })
        .execute();
    },
    [],
  );

  const fetchWorkspaceMembershipAvailabilities = async (workspaceId: string): Promise<void> => {
    const page = 0;
    const pageSize = 1000;
    const timestamp = undefined;

    // TODO: update and re-use timestamp here
    const response = await client.bootstrapWorkspaceMembershipAvailabilities({
      workspaceId,
      page,
      pageSize,
      timestamp,
    });

    await createManyWsMembershipAvailability(response?.membershipAvailabilities || []);
  };

  const fetchGeoCoords = async (
    workspaceId: string,
    membershipIds: string[],
  ): Promise<{ lat: number; lng: number } | null> => {
    const { data } = await client.getLocations(workspaceId, { membershipIds });
    return data[0]?.locations[0]?.latitude
      ? {
          lat: data[0]?.locations[0]?.latitude,
          lng: data[0]?.locations[0]?.longitude,
        }
      : null;
  };

  const fetchFeedPermissions = async (feedId: string) => {
    await downloadPaginatedFeedPermissions({
      client,
      feedId,
      workspaceId: currentWorkspaceId,
    });
    return;
  };

  useEffect(() => {
    const f = async () => {
      try {
        let deviceId = deviceRegistrationId;
        if (!deviceId) {
          deviceId = cuid();
        }

        // prevent double quotes from entering the DB
        const cleanDeviceId = deviceId.replace(/"/g, "");

        const newDeviceReg = {
          id: cleanDeviceId,
          surface: "web",
        } as DeviceRegistration;
        console.log({ newDeviceReg });
        const deviceRegistration = await client.registerDevice({
          deviceRegistration: newDeviceReg,
        });
        if (deviceRegistration?.id) {
          // save to local storage
          await upsertDeviceRegistration(deviceRegistration);
        }
      } catch (error) {
        logger(["Error registering device", error], true);
      }
    };
    f();
  }, []);

  const dataState: DataState = {
    availableWorkspaceRoles,
    getWorkspaceRoleLabel,
    bootstrapComplete,
    firstBootWorkspaceId,
    preferredLanguage,
    setPreferredLanguage: setPreferredLanguage,
    fetchWorkspaceMembership,
    listFeedPermissions,
    fetchWorkspaceMembershipAvailabilities,
    fetchGeoCoords,
    loadWorkspaceWorkflowItems,
    invalidWorkspace,
    setInvalidWorkspace,
    fetchFeedPermissions,
  };
  return <DataContext.Provider value={dataState}>{children}</DataContext.Provider>;
};
export default DataProvider;
