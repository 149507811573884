import AvatarWithStatus from "@/components/AvatarWithStatus";
import StatusLight from "@/components/StatusLight";
import Locator from "@/locator";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { transformWorkspaceMembershipAvailabilityRecord } from "@/utils/transformers";
import { Chip, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function AliasChannelHeader({
  aliasChannel,
}: {
  aliasChannel: { alias: string; title: string; name: string; id: string };
}) {
  const theme = useTheme();
  const { accountMap } = useContext(WorkspaceContext);
  const accountRecordWithStatus = accountMap?.get(aliasChannel?.id);

  const transformedRecord = accountRecordWithStatus?.calculatedAvailability
    ? transformWorkspaceMembershipAvailabilityRecord(accountRecordWithStatus)
    : null;

  const deviceLocation = transformedRecord?.deviceLocation ? transformedRecord.deviceLocation : null;

  const mapLink =
    deviceLocation?.latitude && deviceLocation?.latitude
      ? `https://maps.google.com/?q=${deviceLocation.latitude},${deviceLocation.longitude}`
      : null;

  return (
    <Box
      aria-label={Locator.mainNav.navBar}
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ mr: 2 }}>
          <AvatarWithStatus size={40} accountId={aliasChannel.id} key={aliasChannel.id} />
        </Box>
        <Box>
          <Box sx={{ fontSize: "18px", mb: "-4px", fontWeight: "bold" }} aria-label={Locator.mainNav.displayTitle}>
            {aliasChannel.title}
          </Box>
        </Box>
      </Box>

      {transformedRecord?.color && transformedRecord?.status && (
        <Box
          sx={{
            flex: "0 1 auto",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Box>
              <Box
                sx={{
                  mb: "-2px",
                  textAlign: "right",
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Box>{transformedRecord?.status}</Box>
                <StatusLight sx={{ ml: 1 }} color={transformedRecord.color} size={14} />
              </Box>
            </Box>

            {(transformedRecord?.additionalInfo?.length > 0 || mapLink) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {mapLink && (
                  <Tooltip title="View on map">
                    <Chip
                      key="device-location"
                      size="small"
                      sx={{
                        background: deviceLocation?.stale ? "" : theme.palette.brand.primary.dark,
                        boxShadow: `0 0 0 1px ${deviceLocation?.stale ? theme.palette.secondary.light : theme.palette.brand.primary.main}`,
                      }}
                      label={
                        <Link
                          style={{
                            display: "inline-flex",
                            textDecoration: "none",
                            color: deviceLocation?.stale ? theme.palette.neutral.main : theme.palette.primary.main,
                          }}
                          to={mapLink}
                          target="_blank"
                        >
                          {`${deviceLocation?.municipality}, ${deviceLocation?.region}`}
                        </Link>
                      }
                    />
                  </Tooltip>
                )}

                {transformedRecord.additionalInfo.map((ai, aiIndex) => (
                  <Chip
                    key={`additional-info-${ai}`}
                    sx={{
                      color: theme.palette.primary.main,
                      ml: aiIndex === transformedRecord.additionalInfo.length - 1 ? 1 : 0,
                    }}
                    color="error"
                    size="small"
                    label={ai}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
