import HideShowTranscriptButton from "@/components/HideShowTranscriptButton";
import RichTranscript from "@/components/RichTranscript";
import TranscriptDetails from "@/components/TranscriptDetails";
import { File, Link as LinkType, Transcription } from "@/db/types";
import { ActionContext } from "@/models/ActionsProvider";
import { FeedContext } from "@/models/FeedContextProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { PreferredLanguage } from "@/models/languages";
import BasicButtonStyles from "@/theme/BasicButtonStyles";
import { selectTranscription } from "@/utils";
import * as Icons from "@mui/icons-material";
import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";

type TranscriptProps = {
  displayArtifact: boolean;
  feedItemId: string;
  files?: File[];
  links?: LinkType[];
  mine: boolean;
  preferredLanguage: PreferredLanguage;
  showTranscription: boolean;
  transcriptionContent: Transcription;
  transcriptions: Transcription[];
  unreliableTranscription: boolean;
  unreliableTranscriptLabel: string;
  feedId: string;
  itemId: string;
};
export default function DisplayTranscript({
  displayArtifact,
  feedItemId,
  files,
  links,
  mine,
  preferredLanguage,
  transcriptionContent,
  transcriptions,
  unreliableTranscription,
  unreliableTranscriptLabel,
  feedId,
  itemId,
}: TranscriptProps) {
  const theme = useTheme();
  const { accountEvent } = useContext(ActionContext);
  const { itemTranscriptState, setItemTranscriptState } =
    useContext(FeedContext);
  const { ampli } = useContext(TrackingContext);

  const showTranscription = itemTranscriptState?.includes(itemId);
  const maxHeightBox = 144;
  const boxVerticalPadding = 2;
  const [collapsedSize, setCollapsedSize] = useState<number>(0);
  const [toggleShowMoreButton, setToggleShowMoreButton] =
    useState<boolean>(false);
  const shadowBox = useRef<HTMLDivElement>();

  const toggleShowTranscription = (show: boolean) => {
    if (show) {
      accountEvent("Expanded Transcript", {
        feedId,
        feedItemId: itemId,
      });
      ampli.openTranscript({ itemId: itemId, feedId: feedId });
      setItemTranscriptState([...itemTranscriptState, itemId]);
    } else {
      setItemTranscriptState(itemTranscriptState.filter((id) => id !== itemId));
    }
  };

  const { simpleHtml, richTranscript, textTranscript } = selectTranscription(
    preferredLanguage,
    transcriptions,
  );
  const activeTranscript = simpleHtml ?? richTranscript ?? textTranscript;
  const __html = activeTranscript?.transcriptionContent;

  useEffect(() => {
    const boxHeight = shadowBox.current?.offsetHeight + boxVerticalPadding;

    if (boxHeight > 0 && boxHeight >= maxHeightBox) {
      setCollapsedSize(() => maxHeightBox);
      setToggleShowMoreButton(() => true);
    } else {
      setCollapsedSize(() => boxHeight);
      setToggleShowMoreButton(() => false);
    }
  }, [shadowBox.current?.offsetHeight]);

  return (
    <Box
      id={`${feedItemId}-content`}
      role="textbox"
      data-test="transcription-content"
      aria-label={textTranscript?.transcriptionContent}
    >
      <Collapse
        orientation="vertical"
        in={showTranscription}
        collapsedSize={collapsedSize}
      >
        {unreliableTranscription && (
          <Stack
            sx={{
              background: mine
                ? theme.palette.brand.primary.darker
                : theme.palette.secondary.dark,
              px: 1.5,
              py: 1,
              borderRadius: "8px",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: 0.5,
              marginBottom: "8px",
            }}
          >
            <Icons.InfoOutlined
              role="presentation"
              sx={{
                color: theme.palette.warning.dark,
                fontSize: "1rem",
                marginTop: "3px",
              }}
            />
            <Typography
              sx={{
                fontSize: "0.875rem",
                fontWeight: 500,
                fontStyle: "italic",
                lineHeight: "24px",
              }}
            >
              {unreliableTranscriptLabel}
            </Typography>
          </Stack>
        )}

        <span
          className="display-transcript"
          ref={shadowBox}
          dangerouslySetInnerHTML={{ __html }}
          style={{
            whiteSpace: "pre-line",
          }}
        />
        {!displayArtifact && showTranscription && (
          <TranscriptDetails
            transcriptionContent={transcriptionContent}
            files={files}
            links={links}
          />
        )}
      </Collapse>
      {toggleShowMoreButton && (
        <HideShowTranscriptButton
          mine={mine}
          displayArtifact={displayArtifact}
          showTranscription={showTranscription}
          onClick={() => toggleShowTranscription(!showTranscription)}
        />
      )}
    </Box>
  );
}
