import { FullInput } from "@/components/Utils";
import { useDrizzleSelect } from "@/db/drizzleUtils";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import { groupedFeedsQuery, groupsQuery } from "@/models/commonQueries";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Box, useTheme } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { VList } from "virtua";

export default function SelectRecipients() {
  const theme = useTheme();
  const { myActiveFeedsFiltered } = useContext(LiveQueryContext);
  const { currentWorkspaceId: workspaceId, myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const [filter, setFilter] = useState<string>("");
  const {
    setFleetMessageLatitude,
    setFleetMessageLongitude,
    setFleetMessageLocation,
    fleetMessageActiveChannels,
    setFleetMessageActiveChannels,
  } = useContext(UxContext);

  const cleanupString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const filteredResults = useMemo(() => {
    return myActiveFeedsFiltered?.filter((feed) => cleanupString(feed.title).includes(cleanupString(filter)));
  }, [myActiveFeedsFiltered?.length, filter?.length]);

  const toggleSingleFeed = (id: string) => {
    if (fleetMessageActiveChannels?.includes(id)) {
      setFleetMessageActiveChannels(fleetMessageActiveChannels?.filter((feedId) => feedId !== id));
    } else {
      setFleetMessageActiveChannels([...fleetMessageActiveChannels, id]);
    }
  };

  const { rows: groups } = useDrizzleSelect(
    groupsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const { rows: groupedFeeds } = useDrizzleSelect(
    groupedFeedsQuery({
      workspaceId,
      myCurrentWorkspaceMembershipId: myCurrentWorkspaceMembership?.id,
    }),
  );

  const groupsOfFeeds = useMemo(() => {
    return groups
      ?.map((group) => {
        return {
          ...group,
          feeds: groupedFeeds
            ?.filter((feed) => feed?.groupId === group.id)
            ?.filter((feed) => !feed.readOnly)
            ?.filter((feed) => cleanupString(feed.title).includes(cleanupString(filter))),
        };
      })
      ?.filter((group) => group?.feeds?.length > 0);
  }, [groups, groupedFeeds, filter?.length]);

  const nonGroupedFeeds = useMemo(
    () => filteredResults?.filter((feed) => !feed?.groupId)?.filter((feed) => !feed.readOnly),
    [filteredResults],
  );

  const toggleGroupIds = (ids: string[]) => {
    const hasSome = ids.some((feedId) => fleetMessageActiveChannels?.includes(feedId));
    if (hasSome) {
      setFleetMessageActiveChannels(fleetMessageActiveChannels?.filter((feedId) => !ids.includes(feedId)));
    } else {
      setFleetMessageActiveChannels([...new Set([...fleetMessageActiveChannels, ...ids])]);
    }
  };

  useEffect(() => {
    setFleetMessageActiveChannels([]);
    setFleetMessageLatitude("");
    setFleetMessageLongitude("");
    setFleetMessageLocation("");
  }, []);

  const activeNonGroupedFeeds = useMemo(
    () => nonGroupedFeeds.map((feed) => feed.id).some((feedId) => fleetMessageActiveChannels?.includes(feedId)),
    [nonGroupedFeeds?.length, fleetMessageActiveChannels?.length],
  );

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <FullInput placeholder="Filter Channels" value={filter} onChange={(e) => setFilter(e.target.value)} />
      </Box>
      <VList style={{ height: "330px" }}>
        {groupsOfFeeds?.map((group) => (
          <div key={group.id}>
            <button
              className="basic-button"
              style={{ width: "100%", marginBottom: "0.5rem" }}
              onClick={() => {
                toggleGroupIds(group.feeds.map((feed) => feed.id));
              }}
            >
              {group.feeds.map((feed) => feed.id).some((feedId) => fleetMessageActiveChannels?.includes(feedId)) ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankIcon />
              )}

              <strong>{group.name}</strong>
            </button>

            {group.feeds.map((feed) => feed.id).some((feedId) => fleetMessageActiveChannels?.includes(feedId)) && (
              <div style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}>
                {group?.feeds?.map((feed) => (
                  <button
                    className="basic-button"
                    key={feed.id}
                    style={{ width: "100%", marginBottom: 1 }}
                    onClick={() => toggleSingleFeed(feed.id)}
                  >
                    {fleetMessageActiveChannels?.includes(feed.id) ? (
                      <CheckBoxIcon sx={{ color: theme.palette.info.main }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon sx={{ color: theme.palette.brand.other.gray.main }} />
                    )}
                    <div>{feed.title}</div>
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}

        {nonGroupedFeeds?.length > 0 && (
          <div>
            <button
              className="basic-button"
              onClick={() => {
                toggleGroupIds(nonGroupedFeeds.map((feed) => feed.id));
              }}
              style={{ width: "100%", marginBottom: "0.5rem" }}
            >
              {activeNonGroupedFeeds ? <IndeterminateCheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}

              <strong>Non Grouped Channels</strong>
            </button>

            {activeNonGroupedFeeds && (
              <div style={{ marginLeft: "1rem" }}>
                {nonGroupedFeeds?.map((ngFeed) => (
                  <button
                    className="basic-button"
                    key={ngFeed.id}
                    style={{ width: "100%", marginBottom: "0.5rem" }}
                    onClick={() => toggleSingleFeed(ngFeed.id)}
                  >
                    {fleetMessageActiveChannels?.includes(ngFeed.id) ? (
                      <CheckBoxIcon sx={{ color: theme.palette.info.main }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon sx={{ color: theme.palette.brand.other.gray.main }} />
                    )}

                    <div>{ngFeed.title}</div>
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </VList>
    </Box>
  );
}
